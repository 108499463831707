import './App.css'
import { useEffect, useState } from 'react'
import { defineOneEntry } from 'oneentry'

function App() {
    const API = defineOneEntry(process.env.REACT_APP_API)

    const [status, setStatus] = useState('')

    const api = async () => {
        try {
            const result = await API.Pages.getPageByUrl('home', 'en_US')

            !result.statusCode
                ? setStatus('Work! :)')
                : setStatus(`Doesn't work :(`)
            console.log(await result)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    useEffect(() => {
        api()
    }, [])

    return (
        <div className='App'>
            <header className='App-header'>
                <h1>{status}</h1>
            </header>
        </div>
    )
}

export default App
